import { Box, Card, Grid } from "@mui/material"
import Image from "next/image"
import React from "react"

export type AuthLayoutProps = React.PropsWithChildren<{ noPadding?: boolean }>

const AuthLayout = ({ noPadding, children }: AuthLayoutProps) => {
  return (
    <Box sx={{ height: "100vh" }} className="bg-rogerCharcoalDark">
      <Grid
        container
        direction="column"
        alignItems="center"
        sx={{ minHeight: "100vh" }}
      >
        <Grid item>
          <Box pb={4} marginTop="15vh">
            <Image
              alt="Roger"
              width={174 * 2}
              height={30 * 2}
              src="/roger.svg"
            />
          </Box>
        </Grid>
        <Grid item>
          <Card sx={{ width: 420, maxWidth: "100vw", borderRadius: 6 }}>
            <Box p={noPadding ? undefined : 3}>{children}</Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AuthLayout
