import { Box, Button, Divider, Typography } from "@mui/material"
import AuthLayout from "frontend/components/layout/AuthLayout"
import useLoginGuard from "frontend/hooks/useLoginGuard"
import { signIn } from "next-auth/react"
import Head from "next/head"
import { NextPageWithLayout } from "pages/_app"

const LoginError: NextPageWithLayout = () => {
  useLoginGuard({ guardLoggedOut: true })

  return (
    <>
      <Head>
        <title>404 - Roger</title>
      </Head>
      <Typography variant="h3" component="h1" gutterBottom>
        404 - Page Not Found
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Try another page or login below, if you continue to see this error
        please contact us here:{" "}
        <a href="mailto:support@tryroger.com">support@tryroger.com</a>
      </Typography>
      <Divider />
      <Box py={5} display="flex" justifyContent="center">
        <Button onClick={() => signIn()}>Login</Button>
      </Box>
    </>
  )
}

LoginError.getLayout = (page) => {
  return <AuthLayout>{page}</AuthLayout>
}

export default LoginError
